var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0 mx-0", attrs: { cols: "12" } },
            [_c("app-category-shopping")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 mx-0 px-0", attrs: { cols: "12" } },
            [_c("app-feature-collection")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 mx-0 px-0", attrs: { cols: "12" } },
            [_c("app-feature-card")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 mx-0 px-0", attrs: { cols: "12" } },
            [_c("app-feature-brand")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }