<template>
  <div class="py-8">
    <div class="pb-8">
      <div class="text-center text-md-h4 text-sm-h6 text-body-1 mb-4">
        <span class="font-weight-black unbox_black--text">{{ $t(`label.featured`) }}</span>
        <span class="unbox_black--text">{{ ` ` + $t(`label.brands`) }}</span>
      </div>
      <div class="text-center mb-8">
        <div class="unbox-title-divider">
          <div></div>
          <div class="center-divider"></div>
          <div></div>
        </div>
      </div>
      <div class="pb-1"></div>

      <v-row justify="center" no-gutters>
        <v-col cols="11" md="10" lg="9" xl="8" class="featureBrand-container">
          <div v-for="(brand, index) in brands" :key="`${brand.name}_${index}`" :class="$vuetify.breakpoint.mdAndUp ? 'brand-wrap' : 'brand-wrap-small'">
            <div class="pb-4 mx-2">
              <div class="featureBrand-card ma-auto rounded-lg">
                <div
                  class="brand-image"
                  :class="brand.isSquare ? 'square-image' : ''"
                  :style="`background-image: url('/static/images/brand/${brand.name}.png')`"
                  role="img"
                  :aria-label="brand.name + ' | Featured Brands'"
                ></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFeatureBrand',
  data: () => ({
    brands: [
      {
        name: 'apple',
        isSquare: true
      },
      {
        name: 'dji',
        isSquare: true
      },
      {
        name: 'harmankardon',
        isSquare: false
      },
      {
        name: 'oppo',
        isSquare: false
      },
      {
        name: 'samsung',
        isSquare: false
      },
      {
        name: 'vivo',
        isSquare: false
      },
      {
        name: 'xiaomi',
        isSquare: true
      },
      {
        name: 'realme',
        isSquare: false
      }
    ]
  })
}
</script>
<style lang="scss" scoped>
.featureBrand-container {
  display: flex;
  position: relative;
  overflow: hidden;
  margin: auto;
  flex-wrap: wrap;
}

.brand-wrap-small {
  width: 50%;
  .featureBrand-card {
    height: 60px;
  }
}

.brand-wrap {
  width: 25%;
  .featureBrand-card {
    height: 100px;
  }
}

.featureBrand-hover-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.featureBrand-card {
  height: 100px;
  background-color: var(--v-light_gray-base);
}

.brand-image {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain;
}

.marquee-container {
  text-align: center;
  overflow: hidden;
}
</style>
