var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-8" }, [
    _c(
      "div",
      { staticClass: "pb-8" },
      [
        _c(
          "div",
          { staticClass: "text-center text-md-h4 text-sm-h6 text-body-1 mb-4" },
          [
            _c("span", { staticClass: "font-weight-black unbox_black--text" }, [
              _vm._v(_vm._s(_vm.$t("label.featured"))),
            ]),
            _c("span", { staticClass: "unbox_black--text" }, [
              _vm._v(_vm._s(" " + _vm.$t("label.brands"))),
            ]),
          ]
        ),
        _vm._m(0),
        _c("div", { staticClass: "pb-1" }),
        _c(
          "v-row",
          { attrs: { justify: "center", "no-gutters": "" } },
          [
            _c(
              "v-col",
              {
                staticClass: "featureBrand-container",
                attrs: { cols: "11", md: "10", lg: "9", xl: "8" },
              },
              _vm._l(_vm.brands, function (brand, index) {
                return _c(
                  "div",
                  {
                    key: brand.name + "_" + index,
                    class: _vm.$vuetify.breakpoint.mdAndUp
                      ? "brand-wrap"
                      : "brand-wrap-small",
                  },
                  [
                    _c("div", { staticClass: "pb-4 mx-2" }, [
                      _c(
                        "div",
                        { staticClass: "featureBrand-card ma-auto rounded-lg" },
                        [
                          _c("div", {
                            staticClass: "brand-image",
                            class: brand.isSquare ? "square-image" : "",
                            style:
                              "background-image: url('/static/images/brand/" +
                              brand.name +
                              ".png')",
                            attrs: {
                              role: "img",
                              "aria-label": brand.name + " | Featured Brands",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mb-8" }, [
      _c("div", { staticClass: "unbox-title-divider" }, [
        _c("div"),
        _c("div", { staticClass: "center-divider" }),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }