var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "py-8",
      attrs: { color: "#F8FAFC", tile: "", elevation: "0" },
    },
    [
      _c("div", { staticClass: "pb-8" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass: "text-center text-md-h4 text-sm-h6 text-body-1 mb-2",
            },
            [
              _c(
                "span",
                { staticClass: "font-weight-black unbox_black--text" },
                [_vm._v(_vm._s(_vm.$t("label.featured")))]
              ),
              _c(
                "span",
                { staticClass: "font-weight-black unbox_black--text" },
                [_vm._v(_vm._s(" " + _vm.$t("label.collection")))]
              ),
            ]
          ),
          _c("div", { staticClass: "text-center d-none" }, [
            _c("a", { staticClass: "text-body-2 unbox_primary--text" }, [
              _vm._v(_vm._s(_vm.$t("label.viewAllCollection") + " >")),
            ]),
          ]),
          _c("div", { staticClass: "text-center mb-8" }, [
            _c("div", { staticClass: "unbox-title-divider" }, [
              _c("div"),
              _c("div", { staticClass: "center-divider" }),
              _c("div"),
            ]),
          ]),
        ]),
        _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { justify: "center", "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "11", lg: "9" } },
                  [
                    _c(
                      "v-row",
                      _vm._l(_vm.featuredProduct, function (product, i) {
                        return _c(
                          "v-col",
                          {
                            key: product.group,
                            staticClass: "pb-5",
                            class:
                              i + 1 > _vm.collectionItemLength ? "d-none" : "",
                            staticStyle: { width: "20%" },
                            attrs: {
                              cols: "6",
                              sm: "4",
                              md: "3",
                              lg: "3",
                              xl: "auto",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goToProductDetailPage(product)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "rounded-lg product-card ma-auto cursor-pointer",
                                    attrs: { elevation: "0" },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "full-width",
                                      attrs: {
                                        src: product.gallery[0],
                                        alt: product.name,
                                        title: product.name,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "pa-2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class:
                                              product.highestDiscountedRate != 0
                                                ? "d-visible"
                                                : "d-invisible",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  align: "center",
                                                  "no-gutters": "",
                                                  justify: "space-between",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-caption text-decoration-line-through text_light_gray--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$shared.formatCurrency(
                                                                product.lowestSellingPrice
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    class:
                                                      product.highestDiscountedRate >=
                                                      3
                                                        ? "d-visible"
                                                        : "d-invisible",
                                                    attrs: {
                                                      cols: "4",
                                                      xl: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "productContent-discountRate rounded-lg py-1 text-center text-caption text_light_gray--text",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white--text text-caption text-uppercase font-weight-bold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "label.upTo"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  product.highestDiscountedRate
                                                                ) +
                                                                "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "text-body-2 text-truncate",
                                                            },
                                                            "div",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(product.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(product.name)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "unbox_red--text text-body-1 font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$shared.formatCurrency(
                                                  product.lowestDiscountedPrice
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }