var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-8" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
            _c(
              "div",
              { staticClass: "feature-card-container" },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _vm._l(_vm.featureInformation, function (feature, i) {
                      return [
                        _c(
                          "v-col",
                          {
                            key: "feature_" + i,
                            staticClass: "feature-card",
                            attrs: { cols: "auto", md: "4" },
                          },
                          [
                            _c(
                              "v-img",
                              {
                                staticClass:
                                  "feature-background-image rounded-lg",
                                attrs: {
                                  src: feature.imageSrc,
                                  "aspect-ratio": "432:534",
                                  position: "top center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pa-4 px-8 px-xl-12 px-lg-6",
                                    attrs: {
                                      role: "img",
                                      "aria-label": feature.title,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "white--text text-h5 text-uppercase font-weight-bold pb-6 pb-xl-8 pb-lg-4 pt-4",
                                      },
                                      [_vm._v(_vm._s(feature.title))]
                                    ),
                                    feature.title_2 != ""
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "white--text text-body-subtitle-1 feature-information-subtitle text-uppercase my-4 my-xl-6 my-lg-6",
                                          },
                                          [_vm._v(_vm._s(feature.title_2))]
                                        )
                                      : _vm._e(),
                                    feature.isPaymentSupportTab
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "mx-n2",
                                                attrs: { "no-gutters": "" },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/atome.png",
                                                        alt: "Atome | Payment Method",
                                                        title: "Atome",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/grabPay.png",
                                                        alt: "GrabPay | Payment Method",
                                                        title: "GrabPay",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/boostPay.png",
                                                        alt: "BoostPay | Payment Method",
                                                        title: "BoostPay",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/cimb.png",
                                                        alt: "CIMB | Payment Method",
                                                        title: "CIMB",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/hsbc.png",
                                                        alt: "HSBC | Payment Method",
                                                        title: "HSBC",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pa-2",
                                                    attrs: { cols: "4" },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "feature-card-payment-image",
                                                      attrs: {
                                                        src: "/static/images/footerPayment/maybankQR.png",
                                                        alt: "MayBank QRPay | Payment Method",
                                                        title: "MayBank QRPay",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("div", {
                                      staticClass:
                                        "white--text text-body-2 feature-information-description",
                                      domProps: {
                                        innerHTML: _vm._s(feature.description),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "text-center mt-16" },
                                      [
                                        feature.isActive
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "rounded-pill font-weight-bold text-capitalize",
                                                attrs: { large: "" },
                                                on: {
                                                  click: function ($event) {
                                                    feature.internalLink
                                                      ? _vm.$router.push({
                                                          name: feature.internalLink,
                                                        })
                                                      : _vm.goToExternalLink(
                                                          feature.isActive,
                                                          feature.link
                                                        )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(feature.buttonText) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { right: "" } },
                                                  [_vm._v("mdi-arrow-right")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }