var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "py-8",
      attrs: { color: "#F8FAFC", tile: "", elevation: "0" },
    },
    [
      _c(
        "div",
        { staticClass: "pb-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _vm.showCarousel
                  ? _c(
                      "div",
                      [
                        _c(
                          "carousel",
                          {
                            ref: "banner",
                            attrs: {
                              items: 1,
                              margin: 10,
                              dots: true,
                              nav: false,
                              mouseDrag: false,
                              loop: true,
                              autoplay: true,
                              autoplayTimeout: 5000,
                              autoplayHoverPause: true,
                            },
                          },
                          [
                            _vm._l(_vm.activeBanner, function (banner) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: banner.key,
                                    staticClass: "text-center pa-1",
                                    class: {
                                      "cursor-pointer":
                                        banner.redirectURL != "" ||
                                        banner.key == 1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        banner.key == 1
                                          ? _vm.goToShopByCategory(
                                              undefined,
                                              "iphone 14"
                                            )
                                          : _vm.goToBannerRedirect(banner)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "full-width d-sm-block d-none",
                                      attrs: {
                                        src: banner.srcDesktop,
                                        title: banner.srcTitle,
                                        alt: banner.srcAlt,
                                      },
                                    }),
                                    _c("img", {
                                      staticClass:
                                        "full-width d-sm-none d-block",
                                      attrs: {
                                        src: banner.srcMobile,
                                        title: banner.srcTitle,
                                        alt: banner.srcAlt,
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.showCarousel
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.activeBanner, function (banner) {
                          return [
                            _c(
                              "div",
                              {
                                key: banner.key,
                                staticClass: "text-center pa-1",
                                class: {
                                  "cursor-pointer": banner.redirectURL != "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToBannerRedirect(banner)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "full-width d-sm-block d-none",
                                  attrs: {
                                    src: banner.srcDesktop,
                                    title: banner.srcTitle,
                                    alt: banner.srcAlt,
                                  },
                                }),
                                _c("img", {
                                  staticClass: "full-width d-sm-none d-block",
                                  attrs: {
                                    src: banner.srcMobile,
                                    title: banner.srcTitle,
                                    alt: banner.srcAlt,
                                  },
                                }),
                              ]
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pb-8" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11", lg: "9" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-4",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-left text-md-h4 text-sm-h6 text-body-1",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-black unbox_black--text",
                              },
                              [_vm._v(_vm._s(_vm.$t("label.shopByCategories")))]
                            ),
                          ]
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right my-4",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "gray--text text-capitalize",
                              attrs: {
                                elevation: "0",
                                ripple: false,
                                text: "",
                                depressed: "",
                                to: { name: _vm.routeName.SHOP },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.viewAll")) + " "
                              ),
                              _c("v-icon", {
                                staticClass: "stroke--light_gray",
                                attrs: { small: "", right: "" },
                                domProps: {
                                  textContent: _vm._s("$arrowRightIcon"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.storageProductType != null &&
              _vm.storageProductType.length > 0
                ? _c(
                    "v-col",
                    { staticClass: "p-relative mb-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "center",
                            align: "center",
                            "no-gutters": "",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "backgroundColor--white category-carousel-arrow-button left",
                                  attrs: {
                                    small: "",
                                    fab: "",
                                    elevation: "0",
                                    color: "#E5E5E5",
                                    outlined: "",
                                  },
                                  on: { click: _vm.goPrevious },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { small: "" },
                                    domProps: {
                                      textContent: _vm._s("$arrowLeftIcon"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "11", sm: "11", lg: "9" } },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "carousel",
                                    {
                                      ref: "carousel",
                                      attrs: {
                                        items: _vm.carouselItemLength,
                                        margin: 10,
                                        dots: false,
                                        nav: false,
                                        mouseDrag: false,
                                        loop: true,
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.storageProductType.filter(function (
                                          x
                                        ) {
                                          return x.tier != 1
                                        }),
                                        function (productType) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: productType.value,
                                                staticClass:
                                                  "text-center pa-1 cursor-pointer",
                                                on: {
                                                  click: function () {
                                                    return _vm.goToShopByCategory(
                                                      productType
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      title: "",
                                                      color: "white",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "pt-6 productCategory-image mx-auto",
                                                      attrs: {
                                                        src: productType.imageUrl,
                                                        alt: productType.text,
                                                        title: productType.text,
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "unbox_black--text font-weight-bold text-sm-body-2 text-caption mt-4 pb-4",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            productType.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "backgroundColor--white category-carousel-arrow-button right",
                                  attrs: {
                                    small: "",
                                    fab: "",
                                    elevation: "0",
                                    color: "#E5E5E5",
                                    outlined: "",
                                  },
                                  on: { click: _vm.goNext },
                                },
                                [
                                  _c("v-icon", {
                                    staticClass: "stroke--light_gray",
                                    attrs: { small: "" },
                                    domProps: {
                                      textContent: _vm._s("$arrowRightIcon"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c("v-card", { attrs: { tile: "", elevation: 0 } }, [
                    _c(
                      "div",
                      { staticClass: "py-4 px-8 service-card-container" },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "", align: "center" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "service-card",
                                attrs: { cols: "3" },
                              },
                              [
                                _c("table", { staticClass: "pa-1" }, [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {},
                                      [
                                        _c("v-icon", {
                                          attrs: { "x-large": "" },
                                          domProps: {
                                            textContent: _vm._s(
                                              "$buyNowPayLaterIcon"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", { staticClass: "pl-3" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-body-2 my-2",
                                        },
                                        [_vm._v("Flexible Installments")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-caption unbox_secondary--text my-2",
                                        },
                                        [
                                          _vm._v(
                                            "Buy them NOW. From 0% interest"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "service-card",
                                attrs: { cols: "3" },
                              },
                              [
                                _c("table", { staticClass: "pa-1" }, [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {},
                                      [
                                        _c("v-icon", {
                                          staticClass: "stroke--unbox_primary",
                                          attrs: { "x-large": "" },
                                          domProps: {
                                            textContent:
                                              _vm._s("$deliveryTruckIcon"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", { staticClass: "pl-3" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-body-2 my-2",
                                        },
                                        [_vm._v("Free Shipping")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-caption unbox_secondary--text my-2",
                                        },
                                        [
                                          _vm._v(
                                            "All your purchased products to your doorstep. Absolutely free."
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "service-card",
                                attrs: { cols: "3" },
                              },
                              [
                                _c("table", { staticClass: "pa-1" }, [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _c("v-icon", {
                                          attrs: { "x-large": "" },
                                          domProps: {
                                            textContent:
                                              _vm._s("$shoppingBagIcon"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", { staticClass: "pl-3" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-body-2 my-2",
                                        },
                                        [_vm._v("100% Secure Payment")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-caption unbox_secondary--text my-2",
                                        },
                                        [
                                          _vm._v(
                                            "Trusted, reliable payment system."
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "service-card",
                                attrs: { cols: "3" },
                              },
                              [
                                _c("table", { staticClass: "pa-1" }, [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {},
                                      [
                                        _c("v-icon", {
                                          staticClass: "stroke--unbox_primary",
                                          attrs: { "x-large": "" },
                                          domProps: {
                                            textContent: _vm._s("$packageIcon"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", { staticClass: "pl-3" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-body-2 my-2",
                                        },
                                        [_vm._v("Smooth Experience")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-caption unbox_secondary--text my-2",
                                        },
                                        [
                                          _vm._v(
                                            "Shop, browse, compare, redeem & pay seamlessly."
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }