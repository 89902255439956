<template>
  <v-card class="py-8" color="#F8FAFC" tile elevation="0">
    <div class="pb-8">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <div v-if="showCarousel">
            <carousel ref="banner" :items="1" :margin="10" :dots="true" :nav="false" :mouseDrag="false" :loop="true" :autoplay="true" :autoplayTimeout="5000" :autoplayHoverPause="true">
              <template v-for="banner in activeBanner">
                <div
                  class="text-center pa-1"
                  :key="banner.key"
                  @click="banner.key == 1 ? goToShopByCategory(undefined, 'iphone 14') : goToBannerRedirect(banner)"
                  :class="{ 'cursor-pointer': banner.redirectURL != '' || banner.key == 1 }"
                >
                  <img class="full-width d-sm-block d-none" :src="banner.srcDesktop" :title="banner.srcTitle" :alt="banner.srcAlt" />
                  <img class="full-width d-sm-none d-block" :src="banner.srcMobile" :title="banner.srcTitle" :alt="banner.srcAlt" />
                </div>
              </template>
            </carousel>
          </div>
          <div v-if="!showCarousel">
            <template v-for="banner in activeBanner">
              <div class="text-center pa-1" :key="banner.key" @click="goToBannerRedirect(banner)" :class="{ 'cursor-pointer': banner.redirectURL != '' }">
                <img class="full-width d-sm-block d-none" :src="banner.srcDesktop" :title="banner.srcTitle" :alt="banner.srcAlt" />
                <img class="full-width d-sm-none d-block" :src="banner.srcMobile" :title="banner.srcTitle" :alt="banner.srcAlt" />
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="pb-8">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <v-row no-gutters align="center" class="mb-4">
            <v-col cols="auto">
              <div class="text-left text-md-h4 text-sm-h6 text-body-1">
                <span class="font-weight-black unbox_black--text">{{ $t(`label.shopByCategories`) }}</span>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="text-right my-4">
              <v-btn class="gray--text text-capitalize" elevation="0" :ripple="false" text depressed :to="{ name: routeName.SHOP }">
                {{ $t(`label.viewAll`) }}
                <v-icon small right v-text="'$arrowRightIcon'" class="stroke--light_gray" />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="storageProductType != null && storageProductType.length > 0" class="p-relative mb-5">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="auto">
              <v-btn class="backgroundColor--white category-carousel-arrow-button left" small fab elevation="0" color="#E5E5E5" outlined @click="goPrevious">
                <v-icon v-text="'$arrowLeftIcon'" small></v-icon>
              </v-btn>
            </v-col>
            <v-col cols="11" sm="11" lg="9">
              <div>
                <carousel ref="carousel" :items="carouselItemLength" :margin="10" :dots="false" :nav="false" :mouseDrag="false" :loop="true">
                  <template v-for="productType in storageProductType.filter((x) => x.tier != 1)">
                    <div class="text-center pa-1 cursor-pointer" :key="productType.value" @click="() => goToShopByCategory(productType)">
                      <v-card title color="white">
                        <img class="pt-6 productCategory-image mx-auto" :src="productType.imageUrl" :alt="productType.text" :title="productType.text" />
                        <div class="unbox_black--text font-weight-bold text-sm-body-2 text-caption mt-4 pb-4">{{ productType.text }}</div>
                      </v-card>
                    </div>
                  </template>
                </carousel>
              </div>
            </v-col>
            <v-col cols="auto">
              <v-btn class="backgroundColor--white category-carousel-arrow-button right" small fab elevation="0" color="#E5E5E5" outlined @click="goNext">
                <v-icon v-text="'$arrowRightIcon'" small class="stroke--light_gray"></v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="9">
          <v-card tile :elevation="0">
            <div class="py-4 px-8 service-card-container">
              <v-row no-gutters align="center">
                <v-col class="service-card" cols="3">
                  <table class="pa-1">
                    <tr>
                      <td class="">
                        <v-icon v-text="'$buyNowPayLaterIcon'" x-large></v-icon>
                      </td>
                      <td class="pl-3">
                        <div class="font-weight-bold text-body-2 my-2">Flexible Installments</div>
                        <div class="text-caption unbox_secondary--text my-2">Buy them NOW. From 0% interest</div>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col class="service-card" cols="3">
                  <table class="pa-1">
                    <tr>
                      <td class="">
                        <v-icon v-text="'$deliveryTruckIcon'" class="stroke--unbox_primary" x-large></v-icon>
                      </td>
                      <td class="pl-3">
                        <div class="font-weight-bold text-body-2 my-2">Free Shipping</div>
                        <div class="text-caption unbox_secondary--text my-2">All your purchased products to your doorstep. Absolutely free.</div>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col class="service-card" cols="3">
                  <table class="pa-1">
                    <tr>
                      <td>
                        <v-icon v-text="'$shoppingBagIcon'" x-large></v-icon>
                      </td>
                      <td class="pl-3">
                        <div class="font-weight-bold text-body-2 my-2">100% Secure Payment</div>
                        <div class="text-caption unbox_secondary--text my-2">Trusted, reliable payment system.</div>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col class="service-card" cols="3">
                  <table class="pa-1">
                    <tr>
                      <td class="">
                        <v-icon v-text="'$packageIcon'" class="stroke--unbox_primary" x-large></v-icon>
                      </td>
                      <td class="pl-3">
                        <div class="font-weight-bold text-body-2 my-2">Smooth Experience</div>
                        <div class="text-caption unbox_secondary--text my-2">Shop, browse, compare, redeem & pay seamlessly.</div>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import carousel from 'xx-vue-owl-carousel'
import { storageHelper, sharedHelper } from '@/utils'
import { CACHE_KEY, SHARED } from '@/constants'
import moment from 'moment'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'AppCategoryShopping',
  components: { carousel },
  data: () => ({
    routeName: ROUTE_NAME,
    storageProductType: storageHelper.getLocalStorage(`${CACHE_KEY.PRODUCT_TYPE}`),
    homeBanner: [
      {
        key: 2,
        srcDesktop: '/static/images/other/unbox-malaysia-trade-in-devices-web.jpg',
        srcMobile: '/static/images/other/unbox-malaysia-trade-in-devices-mobile.jpg',
        srcAlt: 'UNBOX Malaysia Trade In | Banner',
        srcTitle: 'UNBOX Malaysia | Makes It Easy To Trade In ',
        redirectURL: ROUTE_NAME.TRADE_IN,
        expiryDate: '',
        startDate: ''
      }
      // {
      //   key: 3,
      //   srcDesktop: '/static/images/other/snbl-desktop.png',
      //   srcMobile: '/static/images/other/snbl-mobile.png',
      //   srcTitle: 'Save Now Buy Later | Banner',
      //   srcAlt: 'Save Now, Buy Later with Split. With UNBOX to get 10% off your purchase. Find out more! | 2-6 Months | Cancel Anytime | 0% Interest | No Hidden Fees | 100% Approval',
      //   redirectURL: ROUTE_NAME.BANNER_REDIRECT_URL_SNBL_LANDING,
      //   expiryDate: '',
      //   startDate: ''
      // }
    ]
  }),
  computed: {
    productTypes() {
      return this.$store.state.lookup.productTypes
    },
    carouselItemLength() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 2
        case 'sm':
          return 3
        case 'md':
          return 4
        case 'lg':
          return 5
        case 'xl':
          return 6
      }
      return 5
    },
    activeBanner() {
      return this.homeBanner.filter((i) => this.checkExpiry(i))
    },
    showCarousel() {
      return this.activeBanner.length > 1
    }
  },
  watch: {
    productTypes() {
      this.storageProductType = this.$store.state.lookup.productTypes
    }
  },
  methods: {
    goNext() {
      this.$refs.carousel.goNext()
    },
    goPrevious() {
      this.$refs.carousel.goPrev()
    },
    goToShopByCategory(productType, keyword) {
      if (productType != undefined) {
        sharedHelper.setShopSearchFilter('category', `${productType.text.toLowerCase().replace(/\s+/g, '-')}-${SHARED.PRODUCT_TYPE_PREFIX}${productType.id}`)
      }
      if (keyword != undefined) {
        sharedHelper.setShopSearchFilter('keyword', `${keyword}`)
      }
      let shopSearchCriteria = sharedHelper.getShopSearchFilter()
      this.$router.push({
        name: ROUTE_NAME.SHOP,
        query: shopSearchCriteria
      })
    },
    goToBannerRedirect(banner) {
      if (banner.redirectURL != '') {
        this.$router.push({
          name: banner.redirectURL
        })
      }
    },
    checkExpiry(banner) {
      let expiryBanner = moment(banner.expiryDate)
      let startBanner = moment(banner.startDate)
      let dNow = moment(new Date())
      let expiryFilter = banner.expiryDate == '' ? true : dNow.isAfter(expiryBanner) ? false : true
      let startFilter = banner.startDate == '' ? true : dNow.isBefore(startBanner) ? false : true
      return startFilter && expiryFilter ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.productCategory-card {
  width: 215px;
  display: inline-block;
}

.productCategory-image {
  width: 50px !important;
}

@media only screen and (max-width: 959px) {
  .service-card-container {
    overflow: hidden;
    overflow-x: auto;

    > div {
      width: 1300px;
    }

    .service-card {
      min-width: 300px;
    }
  }

  .category-carousel-arrow-button {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translate(0px, -50%);

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

@media only screen and (max-width: 599px) {
  .productCategory-image {
    width: 35px !important;
  }
}
</style>
